import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import eng from './languages/eng.json'
import geo from './languages/geo.json'

const resources = {
  en: {
    translation: eng,
  },
  geo: {
    translation: geo,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lng') || 'geo',
})

export default i18n
