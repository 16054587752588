import { Container, Heading, Text, Stack, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import LayoutHoc from '../../components/layout'
// import Slide from '../../components/slide'

const AboutUs = () => {
  const { t } = useTranslation()

  return (
    <LayoutHoc>
      <Flex flexDirection={{ base: 'column', xl: 'unset' }} width='full' p={4}>
        <Stack
          marginBottom={10}
          spacing={4}
          as={Container}
          maxW={'3xl'}
          minW={{ lg: 'lg' }}
          textAlign={'center'}
        >
          <Heading marginBottom={10} fontSize={'3xl'}>
            {t('aboutUs')}
          </Heading>
          <Text textAlign='justify' color={'gray.600'} fontSize={'xl'}>
            {t('aboutUsContent')}
          </Text>
        </Stack>
        {/* temporary disabled, until we have correct pictures  */}
        {/* <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Slide objectFit='contain' />
        </Stack> */}
      </Flex>
    </LayoutHoc>
  )
}

export default AboutUs
