import React from 'react'
import LayoutHoc from '../../components/layout'
import { Box, Flex, Button, Text } from '@chakra-ui/react'
import { AiOutlineHome } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Page404 = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const backToHomeHandler = e => {
    e.preventDefault()
    navigate('/')
  }

  return (
    <LayoutHoc>
      <Flex
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        width='full'
      >
        <Text fontSize='4xl'>404 | {t('Page Not Found')} </Text>
        <Box marginTop='5'>
          <Button
            variant='outline'
            size='lg'
            borderColor='black'
            leftIcon={<AiOutlineHome size='22px' />}
            onClick={backToHomeHandler}
          >
            {t('Back To Home')}
          </Button>
        </Box>
      </Flex>
    </LayoutHoc>
  )
}

export default Page404
