import { Heading, Text, Flex, Box, Image, Grid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LayoutHoc from "../../components/layout";
import { Link, useParams } from "react-router-dom";
import { vehicles } from "../../data/vehicle";
import { manufacturer } from "../../data/manufacturer";
import { useEffect, useState } from "react";
import heroImage from "../../assets/heroImage-removebg-preview.png";
import PropTypes from "prop-types";

const AutoManufacturerCard = ({ activated, picture, name, isSelected }) => {
  const LinkComponent = activated ? Link : Box;
  return (
    <LinkComponent to={`/auto-parts?q=${name}`} style={{ height: "100%" }}>
      <Flex
        p={3}
        border={"1px"}
        borderColor={"blackAlpha.400"}
        borderRadius={20}
        filter={activated ? "none" : "grayscale(70%)"}
        cursor={activated ? "pointer" : "not-allowed"}
        height={"full"}
        _hover={{
          boxShadow: activated ? "lg" : "",
          transform: activated ? "translateY(-3px)" : "",
        }}
      >
        <Image src={picture} width={16} alignSelf={"center"} />
      </Flex>
    </LinkComponent>
  );
};
AutoManufacturerCard.propTypes = {
  activated: PropTypes.bool,
  picture: PropTypes.string,
  name: PropTypes.string,
  isSelected: PropTypes.bool,
};

const AutoModelCard = ({ name, picture }) => {
  return (
    <Link to={name}>
      <Box
        p={3}
        border={"1px"}
        borderColor={"white"}
        borderRadius={20}
        bg={"white"}
        cursor="pointer"
        transition={"all"}
        transitionDuration={"0.2s"}
        _hover={{
          boxShadow: "lg",
          transform: "translateY(-5px)",
        }}
      >
        <Image src={picture} mb={3} />
        <Text
          fontSize="md"
          fontWeight="700"
          borderTop={"1px"}
          borderColor={"blackAlpha.400"}
          mb={2}
          pt={2}
        >
          {name}
        </Text>
      </Box>
    </Link>
  );
};
AutoManufacturerCard.propTypes = {
  name: PropTypes.string,
  picture: PropTypes.string,
};
// telegra

const AutoParts = () => {
  const { t } = useTranslation();
  const [model, setModel] = useState("");

  const par = useParams();
  const params = window.location.href;

  useEffect(() => {
    const splitParams = params.split("q=");
    const readyModel = splitParams[splitParams.length - 1];
    setModel(readyModel.startsWith("http") ? "" : readyModel);
  }, [par, params]);

  return (
    <Box bg={"#eceef7"}>
      <LayoutHoc>
        <Flex
          width={"full"}
          alignItems="center"
          flexDirection={"column"}
          px={"10px"}
        >
          <Box width={"full"} maxW={1150} gap={2} mt={30}>
            <Flex
              flexDirection={{ base: "column", lg: "row" }}
              justifyContent={"space-between"}
            >
              <Flex
                width={{ base: "100%", lg: "60%" }}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                <Text
                  fontSize={28}
                  fontWeight={"800"}
                  fontStyle={"italic"}
                  color={"#26571a"}
                  // textDecoration={"underline"}
                >
                  {t("Direct importer of auto parts in Georgia")}
                </Text>
                <Text
                  fontSize={20}
                  my={"auto"}
                  fontWeight={"500"}
                  color={"#333"}
                >
                  {t(
                    "A wide selection of the best quality auto parts, such as Brands like"
                  )}
                  -{" "}
                  <span
                    style={{
                      color: "#26571a",
                      fontWeight: "700",
                      fontStyle: "italic",
                    }}
                  >
                    Gordon, UP , TongYeong{" "}
                  </span>
                  {t("At the lowest price")}
                </Text>
                <Text
                  fontSize={20}
                  color={"#333"}
                  display={{ base: "hidden", lg: "block" }}
                >
                  {t("Choose a manufacturer")}
                </Text>
              </Flex>
              <Flex flexDirection={"column"}>
                <Image
                  src={heroImage}
                  alignSelf={{ base: "center", lg: "end" }}
                />
                <Text
                  alignSelf={"center"}
                  fontWeight={600}
                  fontSize={{ base: 20, sm: 30 }}
                  display={{ base: "block", lg: "none" }}
                >
                  {t("Choose a manufacturer")}
                </Text>
              </Flex>
            </Flex>
            <Box>
              <Flex
                gap={3}
                mt={4}
                bg={"white"}
                borderRadius={20}
                p={4}
                flexWrap={"wrap"}
              >
                {manufacturer.map((manu, index) => {
                  return (
                    <Flex style={{ boxSizing: "border-box" }} key={index}>
                      <AutoManufacturerCard
                        activated={manu.activated}
                        picture={manu.picture}
                        name={manu.name}
                        isSelected={model === manu.name}
                      />
                    </Flex>
                  );
                })}
              </Flex>
            </Box>
            <Flex
              display={model ? "flex" : "none"}
              gap={3}
              mt={4}
              bg={"white"}
              borderRadius={20}
              p={4}
              flexDirection={"column"}
            >
              <Heading fontSize="lg" color={"#444"}>
                {t("Model")}
              </Heading>
            </Flex>
            <Grid
              gap={3}
              mt={8}
              borderRadius={20}
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
            >
              {vehicles.map((car) => {
                const name =
                  car.name.split(" ")[
                    car.name.split(" ").length - car.name.split(" ").length
                  ];
                return (
                  name === model && (
                    <AutoModelCard
                      key={car.name}
                      name={car.name}
                      picture={car.picture}
                    />
                  )
                );
              })}
            </Grid>
          </Box>
        </Flex>
      </LayoutHoc>
    </Box>
  );
};

export default AutoParts;
