import Slide from '../slide'
import Statistics from '../statistics'
import { Flex } from '@chakra-ui/react'
import WhyUs from '../whyUs'

const MainContent = () => {
  return (
    <Flex width='full' flexDirection='column'>
      <Slide />
      <Statistics />
      <Statistics type='calculator' />
      <WhyUs />
    </Flex>
  )
}

export default MainContent
