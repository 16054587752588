import {
  Box,
  chakra,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FaCarSide } from 'react-icons/fa'
import { BsSearch, BsCurrencyDollar } from 'react-icons/bs'
import { TbShip } from 'react-icons/tb'
import { StatsCardWithIcon } from './whyUs'

const StatsCard = ({ title, stat }) => (
  <Stat
    px={{ base: 4, md: 8 }}
    py={'5'}
    shadow={'xl'}
    border={'1px solid'}
    borderColor={useColorModeValue('gray.800', 'gray.500')}
    rounded={'lg'}
  >
    <StatLabel fontWeight={'medium'}>{title}</StatLabel>
    <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
      {stat}
    </StatNumber>
  </Stat>
)

StatsCard.propTypes = {
  title: PropTypes.string,
  stat: PropTypes.string,
}

const Statistics = ({ type }) => {
  const { t } = useTranslation()

  if (type === 'calculator') {
    return (
      <Flex
        my={5}
        maxW='7xl'
        mx={'auto'}
        pt={5}
        px={{ base: 2, sm: 12, md: 17 }}
        width='full'
        minHeight={{ base: 'fit-content', md: '40' }}
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems='center'
        justifyContent='space-between'
      >
        <Box mx={5}>
          <FaCarSide size={60} />
        </Box>
        <Heading textAlign='center' as='h2'>
          {t('Customs Clearance Calculator')}
        </Heading>
        <Link
          my={2}
          p={4}
          width={{ base: 'full', md: 'fit-content' }}
          minW='200px'
          height='60px'
          rounded={'md'}
          fontWeight='bold'
          border='1px solid black'
          textAlign='center'
          _hover={{
            textDecoration: 'none',
            bg: 'gray.200',
          }}
          target='_blank'
          href={'https://www.rs.ge/CarClearance-en'}
        >
          {t('Calculator')}
        </Link>
      </Flex>
    )
  }

  const statCardArray = [
    {
      text: t('Choose The Car You Want'),
      icon: <BsSearch size={30} />,
    },
    {
      text: t('Purchase'),
      icon: <BsCurrencyDollar size={30} />,
    },
    {
      text: t('Transportation'),
      icon: <TbShip size={30} />,
    },
  ]

  return (
    <Box
      width='full'
      maxW='7xl'
      mx={'auto'}
      pt={5}
      px={{ base: 2, sm: 12, md: 17 }}
    >
      <chakra.h1
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}
      >
        {t('We Offer To:')}
      </chakra.h1>
      <SimpleGrid
        textAlign='center'
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 5, lg: 8 }}
      >
        {statCardArray.map(statCard => (
          <StatsCardWithIcon
            icon={statCard.icon}
            text={statCard.text}
            key={statCard.text}
          />
        ))}
      </SimpleGrid>
    </Box>
  )
}

Statistics.propTypes = {
  type: PropTypes.string,
}

export default Statistics
