import {
  Heading,
  Text,
  Stack,
  Flex,
  Box,
  Image,
  Grid,
  Card,
  CardFooter,
  ListItem,
  ListIcon,
  useToast,
  List,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { useTranslation } from "react-i18next";
import LayoutHoc from "../../components/layout";
import { rentalCars } from "../../data/rentalCars";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import PropTypes from "prop-types";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { useCopyToClipboard } from "react-use";
import { useEffect } from "react";

const CarCard = ({ name, picture, price, numberF, numberS }) => {
  const { t } = useTranslation();
  const [state, copyToClipboard] = useCopyToClipboard();
  const toast = useToast();

  useEffect(() => {
    if (!state.value) return;

    toast({
      title: `${state.value} copied!`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  }, [state.value, toast]);

  return (
    <Card w={"full"} p={4}>
      <Flex flexDirection={"column"}>
        <Flex width={"full"} bg="white">
          <Image
            src={picture}
            alt={`${name} picture`}
            borderRadius="lg"
            width={"full"}
            objectFit={"scale-down"}
            height={"230px"}
          />
        </Flex>
        <Stack mt="6" spacing="3">
          <Heading fontSize={25} fontWeight={"500"}>
            {name}
          </Heading>
          <Flex flexDirection={"column"}>
            <Text>{t("Mandatory conditions")}</Text>
            <Text as="span" fontSize={14} mb={4}>
              ({t("provided by the contract")}):
            </Text>
            <List spacing={3}>
              <Flex>
                <ListIcon as={MdCheckCircle} color="green.500" mt={1} />
                <ListItem>{t("age 21")}</ListItem>
              </Flex>
              <Flex>
                <ListIcon as={MdCheckCircle} color="green.500" mt={1} />
                <ListItem>{t("experience")}</ListItem>
              </Flex>
              <Flex>
                <ListIcon as={MdCheckCircle} color="green.500" mt={1} />
                <ListItem>{t("car rent")}</ListItem>
              </Flex>
              <Flex>
                <ListIcon as={MdCheckCircle} color="green.500" mt={1} />
                <ListItem>{t("deposit")}</ListItem>
              </Flex>
            </List>
          </Flex>
          <Text color="blue.600" fontSize="2xl">
            {price} {t("gel")}
          </Text>
          <Box>
            <Flex
              mb={"10px"}
              alignItems={"center"}
              onClick={() => copyToClipboard(numberF)}
              cursor={"pointer"}
            >
              <FaPhoneAlt />
              <Text>+995-{numberF}</Text>
            </Flex>
            <Flex
              mb={"10px"}
              alignItems={"center"}
              onClick={() => copyToClipboard(numberS)}
              cursor={"pointer"}
            >
              <FaPhoneAlt />
              <Text>+995-{numberS}</Text>
            </Flex>
          </Box>
        </Stack>
        <CardFooter
          flexDirection={{ base: "row", lg: "column", xl: "row" }}
          p={0}
          mb={3}
          gap={3}
        >
          <Link to="//api.whatsapp.com/send?phone=995555330844" width={"100%"}>
            <Flex
              bg={"#25D366"}
              width={"fit-content"}
              gap={{ base: 1, xl: 3 }}
              borderRadius={10}
              py={1}
              px={2}
              pr={{ base: 2, sm: 3 }}
            >
              <FaWhatsapp color="#fff" size={"30"} />
              <Text
                display={{ base: "none", sm: "block" }}
                color="white"
                alignSelf={"center"}
              >
                {t("Contact us")}
              </Text>
            </Flex>
          </Link>
          <Link to="//telegram.me/+995577450906" width={"100%"}>
            <Flex
              bg={"#229AD4"}
              width={"fit-content"}
              gap={{ base: 1, xl: 3 }}
              borderRadius={10}
              py={1}
              px={2}
              pr={{ base: 2, sm: 3 }}
            >
              <FaTelegram color="#fff" size={"30"} />
              <Text
                display={{ base: "none", sm: "block" }}
                color="white"
                alignSelf={"center"}
              >
                {t("Contact us")}
              </Text>
            </Flex>
          </Link>
        </CardFooter>
      </Flex>
    </Card>
  );
};

CarCard.propTypes = {
  name: PropTypes.string,
  picture: PropTypes.string,
  price: PropTypes.number,
};

const Rental = () => {
  const { t } = useTranslation();
  return (
    <Box bg={"#eceef7"}>
      <LayoutHoc>
        <Flex
          width={"full"}
          alignItems="center"
          flexDirection={"column"}
          objectFit="contain"
          px={"10px"}
        >
          <Flex
            width={"full"}
            maxW={1150}
            gap={2}
            flexDirection={"column"}
            height={"full"}
            px={"30px"}
          >
            <Box width={"full"} maxW={1150} gap={2} mt={30}>
              <Heading fontSize="lg" color={"#444"} pl={4}>
                {t("Manufacturer")}
              </Heading>
              <Grid
                gap={3}
                mt={8}
                borderRadius={20}
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                }}
              >
                {rentalCars.map((car) => {
                  return (
                    <CarCard
                      key={car.name}
                      name={car.name}
                      picture={car.picture}
                      price={car.price}
                      numberF={car.numberFirst}
                      numberS={car.numberSecond}
                    />
                  );
                })}
              </Grid>
            </Box>
          </Flex>
        </Flex>
      </LayoutHoc>
    </Box>
  );
};

export default Rental;
