import { useState } from "react";

import { useLocation } from "react-router-dom";
import { Box, IconButton, useBreakpointValue, Image } from "@chakra-ui/react";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import Slider from "react-slick";
import shipBeingLoaded from "../assets/slider/shipBeingLoaded.jpg";
import container from "../assets/slider/container.jpg";
import shipWithContainers from "../assets/slider/shipWithContainers.jpg";
import shipWithContainers1 from "../assets/slider/aarya-tyagi-E7dgUZjkaBg-unsplash.jpg";
import shipwithContainers2 from "../assets/slider/andy-li-CpsTAUPoScw-unsplash.jpg";
import CnanMedShip from "../assets/slider/baptiste-buisson-Nmco1yiSClQ-unsplash.jpg";
import bernd from "../assets/slider/bernd-dittrich-mEbre97xyl4-unsplash.jpg";
import bern1 from "../assets/slider/bernd-dittrich-P_-S57fiFwE-unsplash.jpg";
import borderPortal from "../assets/slider/borderpolar-photographer-AMXFr97d00c-unsplash.jpg";
import david from "../assets/slider/david-vives-zLcV9nXr2y0-unsplash.jpg";
import diego from "../assets/slider/diego-fernandez-6Vg8N8u61aI-unsplash.jpg";
import dominik from "../assets/slider/dominik-luckmann-SInhLTQouEk-unsplash.jpg";
import esmonde from "../assets/slider/esmonde-yong-wFpJV5EWrSM-unsplash.jpg";
import hay from "../assets/slider/hay-s-ZqDTmwz3gqQ-unsplash.jpg";
import jacob from "../assets/slider/jacob-meissner-oGafvInnHlY-unsplash.jpg";
import johan from "../assets/slider/johan-taljaard-csrScXTXdug-unsplash.jpg";
import john from "../assets/slider/john-simmons-UYXBqnIBtuI-unsplash.jpg";
import mAnink from "../assets/slider/m-anink-yo9w2E-WN3c-unsplash.jpg";
import phoyo17 from "../assets/slider/photo-1700718008794-3db41f8d6c16 (2).avif";
import michael from "../assets/slider/michael-up_c1IQN33Q-unsplash.jpg";
import renaldo from "../assets/slider/renaldo-matamoro-DEMrRuvJvqo-unsplash.jpg";
import ronan from "../assets/slider/ronan-furuta-daSq9zhROxY-unsplash.jpg";
import seshu from "../assets/slider/seshu-b-quFbpJIeFnU-unsplash.jpg";
import sven from "../assets/slider/sven-piper-L_TKPq04f4Q-unsplash.jpg";
import venti from "../assets/slider/venti-views-6p0JBES_65E-unsplash.jpg";
import waImg from "../assets/slider/WhatsApp Image 2023-12-04 at 14.42.08 (2).jpeg";
import waImg2 from "../assets/slider/WhatsApp Image 2023-12-04 at 14.42.08.jpeg";
import william from "../assets/slider/william-william-NndKt2kF1L4-unsplash.jpg";

import officeInterior from "../assets/officeInterior.jpg";
import officeExterior from "../assets/officeExterior.jpg";

const settings = {
  dots: true,
  arrows: false,
  Slide: true,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Slide = () => {
  const [slider, setSlider] = useState(null);

  const location = useLocation();
  const isAboutUsPage = location.pathname === "/about-us";

  const top = useBreakpointValue({ base: "90%", md: "50%" });
  const side = useBreakpointValue({ base: "30%", md: "40px" });

  const cards = [
    { image: shipwithContainers2 },
    { image: shipWithContainers1 },
    { image: CnanMedShip },
    { image: bernd },
    { image: container },
    { image: bern1 },
    { image: borderPortal },
    { image: david },
    { image: diego },
    { image: dominik },
    { image: esmonde },
    { image: hay },
    { image: shipBeingLoaded },
    { image: jacob },
    { image: johan },
    { image: john },
    { image: mAnink },
    { image: shipWithContainers },
    { image: phoyo17 },
    { image: michael },
    { image: renaldo },
    { image: ronan },
    { image: seshu },
    { image: sven },
    { image: venti },
    { image: waImg },
    { image: waImg2 },
    { image: william },
  ];

  const cardsForOffice = [{ image: officeInterior }, { image: officeExterior }];

  return (
    <Box
      alignSelf="center"
      position={"relative"}
      height={{ base: "340px", md: "700px" }}
      width={"full"}
      maxW="7xl"
      overflow={"hidden"}
      borderRadius={10}
    >
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <IconButton
        aria-label="left-arrow"
        variant="ghost"
        position="absolute"
        left={side}
        top={top}
        transform={"translate(0%, -50%)"}
        zIndex={2}
        onClick={() => slider?.slickPrev()}
      >
        <BiLeftArrowAlt color={isAboutUsPage ? "black" : "white"} size="40px" />
      </IconButton>
      <IconButton
        aria-label="right-arrow"
        variant="ghost"
        position="absolute"
        right={side}
        top={top}
        transform={"translate(0%, -50%)"}
        zIndex={2}
        onClick={() => slider?.slickNext()}
      >
        <BiRightArrowAlt
          color={isAboutUsPage ? "black" : "white"}
          size="40px"
        />
      </IconButton>
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {(isAboutUsPage ? cardsForOffice : cards).map((card, index) => (
          <Image
            height={{ base: "300px", md: "700px" }}
            key={index}
            position="relative"
            objectFit="cover"
            src={card.image}
          />
        ))}
      </Slider>
    </Box>
  );
};

export default Slide;
