import PriusPic from "../assets/PRIUS-2014-1.webp";
import nissanTiidaPic from "../assets/nissan-tiida-2010-recall.jpg";
import hundai from "../assets/hundai.jpg";
import kia from "../assets/kia.jpg";
import subaru from "../assets/subaru.jpg";
import toyota from "../assets/toyota.jpg";

export const rentalCars = [
  {
    name: "Toyota Prius 2011",
    picture: PriusPic,
    price: 60,
    numberFirst: "555 33 08 44",
    numberSecond: "577 45 09 06",
  },
  {
    name: "Nissan Tiida 2005",
    picture: nissanTiidaPic,
    price: 40,
    numberFirst: "577 45 09 06",
    numberSecond: "555 33 08 44",
  },
  {
    name: "Hyundai Elantra 2017",
    picture: hundai,
    price: 80,
    numberFirst: "555 33 08 44",
    numberSecond: "577 45 09 06",
  },
  {
    name: "Kia Soul 2017",
    picture: kia,
    price: 100,
    numberFirst: "577 45 09 06",
    numberSecond: "555 33 08 44",
  },
  {
    name: "Subaru Forester 2014",
    picture: subaru,
    price: 120,
    numberFirst: "555 33 08 44",
    numberSecond: "577 45 09 06",
  },
  {
    name: "Toyota Prius C 2014",
    picture: toyota,
    price: 60,
    numberFirst: "577 45 09 06",
    numberSecond: "555 33 08 44",
  },
];
