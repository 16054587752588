import {
  Box,
  Container,
  Flex,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Logo from "../../assets/greenAutoImportLogo-removebg-preview.png";

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Flex
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      marginTop="auto"
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ base: "1fr", md: "2fr 2fr 2fr" }}
          spacing={8}
        >
          <Stack align={"center"}>
            <ListHeader>{t("company")}</ListHeader>
            <Link href={"/"}>{t("home")}</Link>
            <Link href={"/about-us"}>{t("aboutUs")}</Link>
            <Link href={"/contact"}>{t("contact")}</Link>
          </Stack>
          <Stack align={"center"}>
            <ListHeader>{t("Follow Us")}</ListHeader>
            <Link
              target="_blank"
              href={"https://www.facebook.com/LogisticC0mpany?mibextid=LQQJ4d"}
            >
              Facebook
            </Link>
          </Stack>
          <Stack align={{ base: "center", md: {} }} spacing={6}>
            <Box>
              <img
                style={{ height: "100px", width: "300px", objectFit: "cover" }}
                src={Logo}
                alt="logo"
              />
            </Box>
            <Text textAlign="center" fontSize={"sm"}>
              Copyright © 2022 Green Auto Import. All rights reserved
            </Text>
          </Stack>
        </SimpleGrid>
      </Container>
    </Flex>
  );
};

export default Footer;
