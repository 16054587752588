import antifreezPic from "../assets/ანტიფრიზის ბაჩოკი.png";
import bumperGrillPic from "../assets/ბამპერის გრილი.webp";
import bumperGrillePic from "../assets/ბამპერის ცხაურა.jpeg";
import screenPic from "../assets/ეკრანი.webp";
import hoodFlapsPic from "../assets/კაპოტის პეტლები.jpeg";
import hoodLatch1Pic from "../assets/კაპოტის ჩამკეტი_1.jpeg";
import airConditionerRadiatorPic from "../assets/კონდიციონერის რადიატორი.jpeg";
import leftWingPic from "../assets/მარცხენა ფრთა.jpg";
import rightMirror1Pic from "../assets/მარჯვენა სარკე_1.webp";
import rightWingPic from "../assets/მარჯვენა ფრთა.jpeg";
import fogLampCoverPic from "../assets/სანისლე ბუდეები .webp";
import fogLapCoverSportPic from "../assets/სანისლე ბუდეები სპორტის.jpg";
import leftMirrorPic from "../assets/სარკე მარცხენა.jpeg";
import rearBumperPic from "../assets/უკანა ბამპერი.webp";
import rearLeftLampPic from "../assets/უკანა ფარი მარცხენა.webp";
import rearRightLampPic from "../assets/უკანა ფარი მარჯვენა.jpeg";
import enginePropellerPic from "../assets/ძრავის პროპელერი.png";
import engineCoverPic from "../assets/ძრავის საფარი.jpeg";
import engineCover1Pic from "../assets/ძრავის საფარის ამორტიზატორები .jpg";
import frontSupportPic from "../assets/წინა ბალკა.jpeg";
import frontBumperPic from "../assets/წინა ბამპერი.jpeg";
import frontHeadlightsRlPic from "../assets/წინა ფარები მარჯვენა.მარცხენა.jpg";
import frontFenderMountsPic from "../assets/წინა ფრთის სამაგრები.webp";
import waterTankPic from "../assets/წყლის ბაჩოკი.jpeg";
import waterRadiatorPic from "../assets/წყლის რადიატორი.webp";
import fogLightSeetPic from "../assets/fog light set.jpg";

export const carParts = [
  {
    name: "Coolant Reservoir",
    inStock: true,
    image: antifreezPic,
    price: 80,
  },
  { name: "Bumper Grill", inStock: true, image: bumperGrillPic, price: 25 },
  { name: "Bumper Grille", inStock: true, image: bumperGrillePic, price: 80 },
  { name: "Screen", inStock: true, image: screenPic, price: 150 },
  { name: "Hood Flaps", inStock: true, image: hoodFlapsPic, price: 30 },
  { name: "Hood Latch", inStock: true, image: hoodLatch1Pic, price: 30 },
  {
    name: "Air Conditioner Radiator",
    inStock: true,
    image: airConditionerRadiatorPic,
    price: 150,
  },
  { name: "Left Wing", inStock: true, image: leftWingPic, price: 80 },
  { name: "Right Mirror", inStock: true, image: rightMirror1Pic, price: 120 },
  { name: "Right Wing", inStock: true, image: rightWingPic, price: 80 },
  { name: "Fog Lamp Cover", inStock: true, image: fogLampCoverPic, price: 10 },
  { name: "Fog Light Set", inStock: true, image: fogLightSeetPic, price: 120 },
  {
    name: "Fog Lap Cover Sport",
    inStock: true,
    image: fogLapCoverSportPic,
    price: 60,
  },
  { name: "Left Mirror", inStock: true, image: leftMirrorPic, price: 120 },
  { name: "Rear Bumper", inStock: true, image: rearBumperPic, price: 320 },
  { name: "Rear Left Lamp", inStock: true, image: rearLeftLampPic, price: 100 },
  {
    name: "Rear Right Lamp",
    inStock: true,
    image: rearRightLampPic,
    price: 100,
  },
  {
    name: "Engine Propeller",
    inStock: true,
    image: enginePropellerPic,
    price: 140,
  },
  { name: "Engine Cover", inStock: true, image: engineCoverPic, price: 270 },
  { name: "Engine Cover 1", inStock: true, image: engineCover1Pic, price: 50 },
  { name: "Front Support", inStock: true, image: frontSupportPic, price: 90 },
  { name: "Front Bumper", inStock: true, image: frontBumperPic, price: 170 },
  {
    name: "Front Headlights RL",
    inStock: true,
    image: frontHeadlightsRlPic,
    price: 220,
  },
  {
    name: "Front Fender Mounts", //წინა ბამპერის სამაგრები
    inStock: true,
    image: frontFenderMountsPic,
    price: 15,
  },
  { name: "Water Tank", inStock: true, image: waterTankPic, price: 30 },
  {
    name: "Water Radiator",
    inStock: true,
    image: waterRadiatorPic,
    price: 150,
  },
];
