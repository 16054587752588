import MainContent from '../../components/Content/content'
import LayoutHoc from '../../components/layout'

const Home = () => {
  return (
    <LayoutHoc>
      <MainContent />
    </LayoutHoc>
  )
}

export default Home
