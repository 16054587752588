import Footer from "./Footer/footer";
import Header from "./Header/header";
import PropTypes from "prop-types";
import { Flex, useBreakpointValue } from "@chakra-ui/react";
import Flags from "./flags";

const LayoutHoc = ({ hasHeader, hasFooter, spacing, children }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Flex flexDirection="column" minHeight="100vh">
      {hasHeader && <Header />}
      <Flex paddingY={spacing} flex="1 1 auto">
        {children}
      </Flex>
      {isDesktop && <Flags />}
      {hasFooter && <Footer />}
    </Flex>
  );
};

LayoutHoc.propTypes = {
  hasHeader: PropTypes.bool,
  hasFooter: PropTypes.bool,
  children: PropTypes.node,
};

LayoutHoc.defaultProps = {
  hasHeader: true,
  hasFooter: true,
  spacing: 6,
};

export default LayoutHoc;
