import {
  Box,
  SimpleGrid,
  VStack,
  chakra,
  Stat,
  StatLabel,
  useColorModeValue,
  Flex,
  Text,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { GiMoneyStack, GiRobber } from 'react-icons/gi'
import { RiAuctionFill, RiCustomerService2Fill } from 'react-icons/ri'
import { FaShippingFast } from 'react-icons/fa'
import { MdPayment } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

const columns = [
  [
    {
      text: 'Access to open and closed auctions',
      icon: <RiAuctionFill size={30} />,
    },
    {
      text: 'Low shipping rates',
      icon: <GiMoneyStack size={30} />,
    },
    {
      text: 'Favorable payment terms',
      icon: <MdPayment size={30} />,
    },
  ],
  [
    {
      text: 'No hidden fees',
      icon: <GiRobber size={30} />,
    },
    {
      text: 'Car insurance',
      icon: <FaShippingFast size={30} />,
    },
    {
      text: 'Personal auto assistant',
      icon: <RiCustomerService2Fill size={30} />,
    },
  ],
]

export const StatsCardWithIcon = ({ text, icon }) => (
  <Stat
    px={{ base: 4, md: 8 }}
    py={'5'}
    shadow={'xl'}
    border={'1px solid'}
    borderColor={useColorModeValue('gray.800', 'gray.500')}
    rounded={'lg'}
    width='full'
    minHeight={{ base: 'fit-content', md: '40' }}
  >
    <Flex
      minHeight={{ base: 'fit-content', md: '100px' }}
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <StatLabel>{icon}</StatLabel>
      <Text fontSize={'2xl'} textAlign='center' fontWeight={'medium'}>
        {text}
      </Text>
    </Flex>
  </Stat>
)

StatsCardWithIcon.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
}

const WhyUs = () => {
  const { t } = useTranslation()
  return (
    <Box
      width='full'
      maxW='7xl'
      mx={'auto'}
      pt={5}
      px={{ base: 2, sm: 12, md: 17 }}
    >
      <chakra.h1
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}
      >
        {t('Why Us?')}
      </chakra.h1>
      <SimpleGrid
        py='5'
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 5, lg: 8 }}
      >
        {columns.map((item, index) => (
          <VStack key={`${item.length} ${index}`} width='full'>
            {item.map(stat => (
              <StatsCardWithIcon
                key={stat.text}
                text={t(stat.text)}
                icon={stat.icon}
              />
            ))}
          </VStack>
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default WhyUs
