import Toyota_Logo from "../assets/toyotalogo-removebg-preview.png";
import Nissan_Logo from "../assets/nissan-logo.png";
import Subaru_Logo from "../assets/subaru-1-1.webp";
import Jeep from "../assets/jeep.png";
import audi from "../assets/audi-1.webp";
import chevro from "../assets/chevrolet-1.webp";
import honda from "../assets/honda-1.webp";
import hyndai from "../assets/hyundai-1.webp";
import lexus from "../assets/lexus-1.webp";
import mazda from "../assets/mazda-1.webp";
import kia from "../assets/ogi-kia-1024x768.webp";

export const manufacturer = [
  {
    name: "Toyota",
    picture: Toyota_Logo,
    activated: true,
  },
  {
    name: "Nissan",
    picture: Nissan_Logo,
    activated: false,
  },
  {
    name: "Subaru",
    picture: Subaru_Logo,
    activated: false,
  },
  {
    name: "Audi",
    picture: audi,
    activated: false,
  },
  {
    name: "Jeep",
    picture: Jeep,
    activated: false,
  },
  {
    name: "Chevrolet",
    picture: chevro,
    activated: false,
  },
  {
    name: "Honda",
    picture: honda,
    activated: false,
  },
  {
    name: "Hyndai",
    picture: hyndai,
    activated: false,
  },
  {
    name: "Lexus",
    picture: lexus,
    activated: false,
  },
  {
    name: "mazda",
    picture: mazda,
    activated: false,
  },
  {
    name: "kia",
    picture: kia,
    activated: false,
  },
];
