import { Text, Flex, Box, Image, Grid, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LayoutHoc from "../../../../components/layout";
import { carParts } from "../../../../data/carParts";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";

const SignlePart = () => {
  const { t } = useTranslation();
  const url = window.location.href;
  const splitUrl = url.split("/");
  const readyUrl = splitUrl[splitUrl.length - 1].replace(/%20/g, " ");
  const lastUrl = splitUrl[splitUrl.length - 2];
  const carName = splitUrl[splitUrl.length - 2].replace(/%20/g, " ");

  return (
    <Box bg={"#eceef7"}>
      <LayoutHoc>
        <Flex
          width={"full"}
          alignItems="center"
          flexDirection={"column"}
          objectFit="contain"
          px={"10px"}
        >
          <Flex
            width={"full"}
            maxW={1150}
            gap={2}
            flexDirection={"column"}
            height={"full"}
            px={"30px"}
          >
            <Flex bg={"white"} p={4} borderRadius={10}>
              <Link to={`/auto-parts/${lastUrl}`}>
                <Button colorScheme="blue">&larr; {t("Go back")}</Button>
              </Link>
            </Flex>
            {carParts.map(
              (item) =>
                readyUrl === item.name && (
                  <Grid
                    key={item.name}
                    gridTemplateColumns={{
                      base: "repeat(1,1fr)",
                      lg: "repeat(2,1fr)",
                    }}
                    gap={5}
                    height={"100%"}
                  >
                    <Image
                      src={item.image}
                      borderRadius={20}
                      height={"full"}
                      bg={"white"}
                      w={"full"}
                      objectFit={"scale-down"}
                    />
                    <Flex
                      flexDirection={"column"}
                      justifyContent={"space-between"}
                    >
                      <Flex flexDirection={"column"} gap={5}>
                        <Text fontSize={"40px"}>{t(item.name)}</Text>
                        <Flex gap={2}>
                          <Box
                            width={3}
                            height={3}
                            bg={"#0CCE9F"}
                            alignSelf={"center"}
                            borderRadius={50}
                          />
                          <Text color={"#0CCE9F"} fontSize={"20px"}>
                            {item.inStock ? t("In stock") : t("Out of stock")}
                          </Text>
                        </Flex>
                        <Text fontSize={"22px"}>{t("large purchase")}</Text>
                        <Text fontSize={"22px"}>{carName}</Text>
                        <Text fontSize={"30px"}>{item.price}₾</Text>
                      </Flex>
                      <Flex flexDirection={"column"} gap={5}>
                        <Link
                          to="//api.whatsapp.com/send?phone=995555330844"
                          style={{
                            width: "fit-content",
                            borderRadius: "10px",
                          }}
                        >
                          <Flex
                            bg={"#25D366"}
                            width={"fit-content"}
                            py={3}
                            px={8}
                            gap={3}
                            borderRadius={10}
                          >
                            <FaWhatsapp color="#fff" size={"30"} />
                            <Text color="white" alignSelf={"center"}>
                              {t("Contact us")}
                            </Text>
                          </Flex>
                        </Link>
                        <Link
                          to="//telegram.me/+995577450906"
                          style={{
                            width: "fit-content",
                            borderRadius: "10px",
                          }}
                        >
                          <Flex
                            bg={"#229AD4"}
                            width={"fit-content"}
                            py={3}
                            px={8}
                            gap={3}
                            borderRadius={10}
                            alignItems={"center"}
                          >
                            <FaTelegram color="#fff" size={"30"} />
                            <Text color="white" alignSelf={"center"}>
                              {t("Contact us")}
                            </Text>
                          </Flex>
                        </Link>
                      </Flex>
                    </Flex>
                  </Grid>
                )
            )}
          </Flex>
        </Flex>
      </LayoutHoc>
    </Box>
  );
};

export default SignlePart;
