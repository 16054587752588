import {
  Heading,
  Text,
  Flex,
  Box,
  Image,
  Grid,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LayoutHoc from "../../../components/layout";
import { Link } from "react-router-dom";
import { carParts } from "../../../data/carParts";
import PropTypes from "prop-types";

const VehiclePart = ({ inStock, name, pic, price }) => {
  const { t } = useTranslation();

  const url = window.location.href;
  const splitUrl = url.split("/");
  const readyUrl = splitUrl[splitUrl.length - 1];

  return (
    <Link to={`/auto-parts/${readyUrl}/${name}`}>
      <Flex
        bg={"white"}
        borderRadius={20}
        flexDirection={"column"}
        alignItems={"center"}
        p={3}
        height={"full"}
        _hover={{
          boxShadow: "lg",
          transform: "translateY(-5px)",
        }}
      >
        <Image src={pic} height={"200px"} />
        <Box width={"full"}>
          <Flex
            width={"full"}
            gap={3}
            borderBottom={"1px"}
            borderColor={"blackAlpha.300"}
          >
            <Box
              width={3}
              height={3}
              bg={inStock ? "#0CCE9F" : "#FF0000"}
              alignSelf={"center"}
              borderRadius={50}
            />
            <Text color={inStock ? "#0CCE9F" : "#FF0000"}>
              {inStock ? t("In stock") : t("Out of stock")}
            </Text>
          </Flex>
          <Text my={2} fontWeight={"600"} fontSize={17}>
            {t(name)}
          </Text>
          <Text mb={4} fontWeight={"600"} fontSize={18}>
            {price}₾
          </Text>
        </Box>
      </Flex>
    </Link>
  );
};

VehiclePart.propTypes = {
  inStock: PropTypes.bool,
  name: PropTypes.string,
  pic: PropTypes.string,
  price: PropTypes.number,
};

const SignleVehicleParts = () => {
  const { t } = useTranslation();
  const url = window.location.href;
  const urlSplitting = url.split("/");
  const name = urlSplitting[urlSplitting.length - 1].replace(/%20/g, " ");
  return (
    <Box bg={"#eceef7"}>
      <LayoutHoc>
        <Flex
          width={"full"}
          alignItems="center"
          flexDirection={"column"}
          px={"10px"}
        >
          <Box width={"full"} maxW={1150} gap={2} mt={30}>
            <Heading fontSize="lg" color={"#444"} pl={4}>
              {t("Manufacturer")}
            </Heading>
            <Flex
              bg={"white"}
              p={4}
              borderRadius={10}
              mt={5}
              justifyContent={"space-between"}
              position="sticky"
              top={"65px"}
              zIndex={10}
              border={"1px"}
              borderColor={"#eceef7"}
            >
              <Link to="/auto-parts?q=Toyota">
                <Button colorScheme="blue">&larr; {t("Go back")}</Button>
              </Link>
              <Text alignSelf={"center"} fontSize={18} fontWeight={600}>
                {name}
              </Text>
            </Flex>
            <Grid
              gap={15}
              mt={8}
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              }}
            >
              {carParts.map((item) => {
                return (
                  <VehiclePart
                    key={item.name}
                    inStock={item.inStock}
                    name={item.name}
                    pic={item.image}
                    price={item.price}
                  />
                );
              })}
            </Grid>
          </Box>
        </Flex>
      </LayoutHoc>
    </Box>
  );
};

export default SignleVehicleParts;
