import LayoutHoc from "../../components/layout";
import {
  Box,
  Heading,
  IconButton,
  Button,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  Link,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { MdPhone, MdEmail, MdLocationOn, MdFacebook } from "react-icons/md";
import location from "../../assets/location.png";
import { useTranslation } from "react-i18next";
import { useCopyToClipboard } from "react-use";
import { useEffect } from "react";

const Contact = () => {
  const { t } = useTranslation();
  const [state, copyToClipboard] = useCopyToClipboard();
  const toast = useToast();

  useEffect(() => {
    if (!state.value) return;

    toast({
      title: `${state.value} copied!`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  }, [state.value, toast]);

  const contactArray = [
    {
      text: "+995-555 330 844",
      icon: <MdPhone color="black" size="20px" />,
    },
    {
      text: "+995-577 450 906",
      icon: <MdPhone color="black" size="20px" />,
    },
    {
      text: "info.usimport@gmail.com",
      icon: <MdEmail color="black" size="20px" />,
    },
    {
      text: t("7 Tsotne Dadiani St, Tbilisi"),
      icon: <MdLocationOn color="black" size="20px" />,
      href: "https://goo.gl/maps/82aMM3rBvaCCAPrN9",
    },
    {
      text: t("xashuri rustaveli n93"),
      icon: <MdLocationOn color="black" size="20px" />,
      href: "https://maps.app.goo.gl/S365dqFM51EqwDxB8",
    },
    {
      text: t("foti larnakas n3"),
      icon: <MdLocationOn color="black" size="20px" />,
      href: "https://maps.app.goo.gl/qZ1JvdCJsNH6EjE68",
    },
  ];

  return (
    <LayoutHoc>
      <Flex
        width="full"
        px={2}
        gap={2}
        flexDirection={{ base: "column", md: "row" }}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          height="full"
          bg="gray.200"
          color="black"
          borderRadius="lg"
          width={{ base: "max-content" }}
          p={{ sm: 5, md: 5, lg: 16 }}
        >
          <Box p={4}>
            <Wrap spacing={{ base: 20 }}>
              <WrapItem>
                <Box>
                  <Heading>{t("contact")}</Heading>
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                    <VStack pl={0} spacing={3} alignItems="flex-start">
                      {contactArray.map((item) =>
                        item.href ? (
                          <Link
                            href={item.href}
                            target="_blank"
                            key={item.text}
                          >
                            <Button
                              size="md"
                              height="48px"
                              width="max-content"
                              variant="ghost"
                              color="gray.800"
                              _hover={{ border: "none" }}
                              leftIcon={item.icon}
                            >
                              {item.text}
                            </Button>
                          </Link>
                        ) : (
                          <Button
                            key={item.text}
                            size="md"
                            height="48px"
                            width="max-content"
                            variant="ghost"
                            color="gray.800"
                            _hover={{ border: "none" }}
                            leftIcon={item.icon}
                            onClick={() => copyToClipboard(item.text)}
                          >
                            {item.text}
                          </Button>
                        )
                      )}
                    </VStack>
                  </Box>
                  <HStack
                    mt={{ lg: 10, md: 10 }}
                    spacing={5}
                    px={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Link
                      target="_blank"
                      href={
                        "https://www.facebook.com/LogisticC0mpany?mibextid=LQQJ4d"
                      }
                    >
                      <IconButton
                        aria-label="facebook"
                        variant="ghost"
                        size="lg"
                        isRound={true}
                        _hover={{ bg: "none" }}
                        icon={<MdFacebook size="28px" />}
                      />
                    </Link>
                  </HStack>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
        <Link
          href="https://goo.gl/maps/82aMM3rBvaCCAPrN9"
          target="_blank"
          height="full"
          width="full"
          maxW="3xl"
          minW={{ base: "sm" }}
          marginTop={{ base: 5, md: 0 }}
          bg="gray.200"
          borderRadius="lg"
        >
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={location}
            alt="pic"
          />
        </Link>
      </Flex>
    </LayoutHoc>
  );
};

export default Contact;
